.canvas div {
  margin: auto;
}

.canvas canvas {
  border-radius: 4px;
}

.panning {
  cursor: grab;
}

.selecting {
  cursor: crosshair;
}
