.completedIconContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #2eccb0;
}

.completedIcon {
  height: 48px;
  width: 48px;
  margin: 16px;
}
